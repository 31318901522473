header{
    background-image: url("../images/portfolio_header_bg_mobile.png") !important;
    width: 100% !important;
    height: 960px !important;
}
.first{
    font-family: "MonumentExtended-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: white;
    margin-top: 450px;
}
.second{
    width: 393px;
    font-family: "MonumentExtended-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #A2F36F;
    margin: 0 auto;
}
.text{
    margin: 0 auto;
    width: 440px;
    height: 110px;
}
@media screen and (min-width: 1024px) {
    header{
        background-image: url("../images/portfolio_header_bg_tablet.png") !important;
        background-size: contain;
        height: 873px !important;
    }
    .text{
        width: 994px;
        position: absolute;
        top: 290px;
        left: 20px;
    }
    .first{
        margin-top: 0;
        font-size: 24px;
        line-height: 29px;
    }
    .second{
        width: inherit;
        font-size: 24px;
        line-height: 29px;
    }
}
@media screen and (min-width: 1920px) {
    header{
        background-image: url("../images/portfolio_header_bg_pc.png") !important;
        background-size: contain;
        height: 873px !important;
    }
    .text{
        width: 1360px;
        position: absolute;
        top: 485px;
        left: 270px;
    }
    .first{
        margin-top: 0px;
        font-size: 36px;
        line-height: 43px;
    }
    .second{
        width: inherit;
        font-size: 36px;
        line-height: 43px;
    }
}
