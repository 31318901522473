.clientsBG{
    background-color: #080612;
    overflow: hidden;
    padding-bottom: 74px;
}
.fixposition{
    position: relative;
    top: -41px;
}

.header{
    top: -59px;
    position: relative;
    font-family: "MonumentExtended-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
    color: #FFFFFF;
    text-align: center;
}
.duplicate{
    transform: translate(37%, -15%);
    font-family: "MonumentExtended-Regular";
    position: relative;
    left: -172px;
    font-style: normal;
    font-weight: normal;
    font-size: 49px;
    line-height: 58px;
    text-align: center;
    color: rgba(255, 255, 255, 0.05);
}
.selectedClients{
    margin-top: 47px;
    font-family: "MonumentExtended-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
}
.description{
    margin: 0 auto;
    margin-top: 8px;
    width: 291px;
    height: 60px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF;
}
.fixposition{
    display: flex;
    flex-direction: column;
}
@media screen and (min-width: 1024px) {
    .duplicate {
        padding-top: 10px;
        font-size: 72px;
        left: -379px;
    }
    .selectedClients{
        font-size: 36px;
    }
    .description{
        margin-top: 15px;
        width: 560px;
        font-size: 24px;
        line-height: 29px;
    }
}
@media screen and (min-width: 1920px) {
    .duplicate {
        padding-top: 23px;
        font-size: 96px;
        left: -710px;
    }
    .header{
        font-size: 48px;
    }
    .selectedClients{
        font-size: 36px;
    }
    .description{
        margin-top: 15px;
        width: 560px;
        font-size: 24px;
        line-height: 29px;
        height: 170px;
    }
    .fixposition{
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-top: 150px;
    }
    .selectedClientsDesc{
        width: 560px;
    }
}
