.container{
    width: 364px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 55px;
}
.logo{
    width: 94px;
    height: auto;
    margin-bottom: 78px;
}
.logo:nth-last-of-type(-n+3){
    margin-bottom: 0px;
}
@media screen and (min-width: 1024px) {
    .container{
        width: 713px;
        margin-top: 120px;
    }
    .logo{
        width: 184px;
    }
}
@media screen and (min-width: 1920px) {
    .container{
        width: 713px;
        margin: 0;
    }
}
