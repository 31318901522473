@font-face {
    font-family: "MonumentExtended-Regular";
    src: local("MonumentExtended-Regular"),
    url("../../fonts/MonumentExtended-Regular.otf") format("truetype");
}
.descriptionBG{
    background-color: #080612;
    padding-bottom: 50px;
}
.decorativeLines{
    transform: translate(5%, -15%);
    z-index: 1;
    background: linear-gradient(
            180deg
            , #FFFFFF 0%, rgba(255, 255, 255, 0) 0.01%, rgba(255, 255, 255, 0.7) 100%);
    opacity: 0.2;
    top: 815px;
    width: 437px;
    height: 472px;
    position: absolute;
    background-image: url("../images/lines_bg_mob.png");
}
.first{
    position: relative;
    z-index: 3;
    width: 440px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px;
    color: #FFFFFF;
}
.second{
    position: relative;
    z-index: 3;
    width: 440px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    margin: 0 auto;
    color: #A2F36F;
}
.line{
position: absolute;
    top: 1005px;
    z-index: 2;
    width: 100%;
}
@media screen and (min-width: 1024px) {
    .descriptionBG{
        padding-bottom: 100px;
    }

    .first, .second{
        width: 822px;
        font-size: 24px;
    }
    .line{
        top: 900px
    }
    .decorativeLines{
        width: 754px;
        margin: 0 auto;
        left: 100px;
        top: 800px;
        background-image: url("../images/lines_bg_tablet.png");
    }
}
@media screen and (min-width: 1920px) {
    .first, .second{
        width: 1360px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
    }
    .line{
        top: 1075px;
    }
    .descriptionBG{
        padding-top: 0px;
    }
    .decorativeLines{
        width: 1541px;
        margin: 0 auto;
        left: 100px;
        top: 1080px;
        background-image: url("../images/lines_bg_pc.png");
    }
}
