@font-face {
    font-family: "MonumentExtended-Regular";
    src: local("MonumentExtended-Regular"),
    url("../../fonts/MonumentExtended-Regular.otf") format("truetype");
}
video{
    display: block;
}
.headerBg{
    background-image: url("../images/hero_bg_mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: inline-block;
    width: 480px;
    height: 960px;
}
.navigation{
    display: flex;
    justify-content: space-between;
    height: 90px;
    width: 480px;
}
.navigationFixed{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 90px;
    position: fixed;
    z-index: 500000;
}
.sheroLogo{
    width: 87px;
    height: 22px;
    margin-top: 34px;
    margin-left: 20px;
}
.burger{
    cursor: pointer;
    padding: 0;
    border: none;
    background-color: transparent;
    width: 30px;
    height: 21px;
    background-image: url("../images/burger_icon.png");
    margin-top: 34px;
    margin-right: 20px;
}
.headerText{
    font-family: 'MonumentExtended-Regular';
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 29px;
    color: white;
    display: block;
    margin: auto;
    width: 164px;
    height: 29px;
    position: absolute;
    top: 813px;
    left: 160px;
}
.headerText:after{
    content: '';
    display: block;
    width: 40px;
    height: 12px;
    margin: 21px auto 85px;
    background-image: url("../images/Arrow.png");
    background-repeat: no-repeat;
}
.headerNav{
    display: flex;
    justify-content: space-between;
    width: 355px;
    list-style-type: none;
    align-items: center;
    margin-right: 95px;
}
.headerNavItem a{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
}
.selectedNav{
    color: #A2F36F !important;
}
@media screen and (min-width: 1024px) {
    .headerBg{
        background-image: url("../images/hero_bg_1024.png");
        width: 100%;
        height: 768px !important;
    }
    .navigation{
        width: 1024px;
    }
    .navigationFixed{
        width: 100%;
        height: 100px;
        position: fixed;
    }
    .sheroLogo{
        width: 133px;
        height: 34px;
        margin-top: 34px;
        margin-left: 15px;
    }
    .headerText{
        font-size: 36px;
        width: 264px;
        top: 647px;
        position: absolute;
        left: 400px;
        margin-top: 0px;
    }
}
@media screen and (min-width: 1920px) {
    .logoBtn{
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .headerBg{
        background-image: url("../images/hero_bg_1920.png") !important;
        width: 100% !important;
        height: 1080px !important;
    }
    .navigation{
        width: 1920px;
    }
    .navigationFixed{
        width: 100%;
        height: 100px;
        position: fixed;
    }
    .sheroLogo{
        width: 133px;
        height: 34px;
        margin-top: 0px;
        margin-left: 56px;
    }
    .headerText{
        font-size: 36px;
        line-height: 43px;
        width: 264px;
        top: 870px;
        position: absolute;
        left: 830px;
        margin-top: 0px;
    }
    .headerNav{
        width: 578px;
    }
    .burger{
        margin-top: 39px;
        margin-right: 63px;
    }
    .headerNavItem a{
        font-size: 24px;
        line-height: 29px;
    }
}
