@font-face {
    font-family: "MonumentExtended-Regular";
    src: local("MonumentExtended-Regular"),
    url("../../fonts/MonumentExtended-Regular.otf") format("truetype");
}
.projectsBG{
    background-color: #080612;
    overflow: hidden;
}
.header{
    font-family: "MonumentExtended-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
    color: #FFFFFF;
    text-align: center;
    padding-bottom: 31px;
}
.duplicate{
    transform: translate(19%, -15%);
    font-family: "MonumentExtended-Regular";
    position: absolute;
    top: 1158px;
    font-style: normal;
    font-weight: normal;
    font-size: 49px;
    line-height: 58px;
    text-align: center;
    color: rgba(255, 255, 255, 0.05);
}
.slider{
    width: 460px;
    height: 302px;
    margin-top: 40px;
}
.descLink{
    text-decoration: none;
    color: black;
}
.descProj{
    width: 300px;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
}
.number{
    position: absolute;
    top: -42px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 78px;
    color: transparent;
    -webkit-text-stroke: 1px white;
    z-index: 1;
}
.title{
    position: relative;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    color: #FFFFFF;
}
.moreProjectLink{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family: 'MonumentExtended-Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    width: 286px;
    height: 61px;
    border: 2px solid #A2F36F;
    box-sizing: border-box;
    border-radius: 15px;
    margin: 50px auto 100px;

}
.portfolioContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.filterButtons{
    width: 300px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
}
.filterBtn{
    border: none;
    outline: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    background-color: transparent;
    color: #FFFFFF;
}
.filterBtnActive{
    border: none;
    outline: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    background-color: transparent;
    color: #A2F36F;
}
.works{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 440px;
}
.works img{
    width: 100%;
    border-radius: 15px;
}
.works p{
    font-family: 'MonumentExtended-Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;
    padding-top: 25px;
}
.work:not(:first-child){
    margin-top: 38px;
}
.work:last-child{
    padding-bottom: 181px;
}
.swiperIMG {
    border-radius: 15px;
    width: 380px;
}
@media screen and (min-width: 1024px) {
    .filterButtons{
        width: 675px;
        padding-top: 0px;
    }
    .filterButtons button{
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        padding: 12px 25px;
    }
    .filterBtnActive{
        border: 1px solid #A2F36F;
        box-sizing: border-box;
        border-radius: 15px;
    }
    .duplicate{
        font-size: 72px;
        top: 1075px;
        left: 155px;
    }
    .header{
        padding-bottom: 72px;
    }
    .works{
        width: 905px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        padding-bottom: 85px;
    }
    .works img{
        display: block;
        width: 99.5%;
        height: 99%;
        margin: 2px auto;
    }
    .work{
        margin-top: 0 !important;
        margin-bottom: 15px !important;
        padding: 0 !important;
        width: 436px;
    }
    .work img{
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
    }
    .hoverTitle{
        font-family: 'MonumentExtended-Regular';
        font-style: normal;
        font-weight: normal;
        display: none;
        position: relative;
        font-size: 48px;
        line-height: 58px;
        top: -40px;
        height: 0;
    }
    .work:hover .hoverTitle{
        display: block;
        padding-top: 0;
    }
    .work:hover img{
        opacity: 0.3;
        transition: opacity 0.3s ease-in-out;
    }
    .work:hover{
        background-image: radial-gradient(circle at 100% 100%, transparent 18px, #080612 18px, #080612 20px, transparent 20px), linear-gradient(to right, #080612, #080612), radial-gradient(circle at 0% 100%, transparent 18px, #080612 18px, #080612 20px, transparent 20px), linear-gradient(to bottom, #080612, #ffffff), radial-gradient(circle at 0% 0%, transparent 18px, #ffffff 18px, #ffffff 20px, transparent 22px), linear-gradient(to left, #ffffff, #ffffff), radial-gradient(circle at 100% 0%, transparent 18px, #ffffff 18px, #ffffff 20px, transparent 22px), linear-gradient(to top, #ffffff, #080612);
        background-size: 20px 20px, calc(100% - 40px) 2px, 20px 20px, 2px calc(100% - 40px);
        background-position: top left,top center,top right,center right, bottom right,bottom center,bottom left,center left;
        background-repeat: no-repeat;
    }
    .workContainer{
        display: flex;
        flex-wrap: wrap;
        width: 902px;
        margin: 0 auto;
        justify-content: space-around;
    }
    .workContainer a img{
        width: 436px;
    }
}
@media screen and (min-width: 1920px) {
    .filterButtons{
        width: 675px;
        padding-top: 0px;
        margin-bottom: 100px;
    }
    .filterButtons button{
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        padding: 12px 25px;
    }
    .filterBtnActive{
        border: 1px solid #A2F36F;
        box-sizing: border-box;
        border-radius: 15px;
    }
    .duplicate{
        font-size: 72px;
        top: 1375px !important;
        left: 155px;
    }
    .header{
        padding-bottom: 72px;
    }
    .works{
        width: 1358px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        padding-bottom: 85px;
    }
    .work{
        margin-top: 0 !important;
        margin-bottom: 44px !important;
        padding: 0 !important;
        width: 657px;
    }
    /*-----*/
    .header{
        font-size: 48px;
    }
    .duplicate{
        font-size: 96px;
        top: 1365px;
        left: 485px;
    }
    .header{
        padding-bottom: 72px;
    }
    .swiperIMG {
        width: 580px;
    }
    .workContainer{
        display: flex;
        flex-wrap: wrap;
        width: 1360px;
        margin: 0 auto;
        justify-content: space-around;
    }
    .workContainer a img{
        width: 657px;
    }
}
