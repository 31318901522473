.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    background-color: #080612;
    text-align: center;
    font-size: 18px;
    /* Center slide text vertica   lly */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.swiper-button-next::after, .swiper-button-prev::after{
    font-size: 25px !important;
    color: #FAFAFA !important;
}
.swiper-slide-active img{
    transform: scale(1);
    position: unset;
}
.swiper-slide{
    display: flex;
    flex-direction: column;
    align-items: center;
}

