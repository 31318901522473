.overflow{
    width: 100%;
    height: 100vh;
    background-color: #080612;
    opacity: 1;
    position: fixed;
    z-index: 99999999999999 !important;
}
.headerBG .navigation{
    pointer-events: none;
}
.sheroLogo{
    width: 87px;
    height: 22px;
    margin-top: 34px;
    margin-left: 20px;
    position: absolute;
    z-index: 9999999;
    opacity: 1;
}
.burger{
    cursor:pointer;
    padding: 0;
    border: none;
    background-color: transparent;
    width: 21px;
    height: 21px;
    background-image: url("../images/closeIcon.png");
    margin-top: 34px;
    margin-right: 20px;
}
.navigation{
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
}
.navigation li:not(:last-child){
    margin-bottom: 25px;
}
a{
    text-decoration: none;
    color: white;
    font-family: "MonumentExtended-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 58px;
    /* identical to box height */

    text-align: center;
}
.navigationMenu{
    list-style-type: none;
    color: white;
    width: 75%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.social{
    list-style-type: none;
    margin: 0 auto;
    width: 290px;
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
}
@media screen and (min-width: 1024px){
    .sheroLogo{
        width: 133px;
        height: 34px;
        margin-top: 34px;
        margin-left: 15px;
    }
    .navigation{
        width: 950px;
        margin: 0 auto;
        background-image: radial-gradient(circle at 100% 100%, transparent 27px, #ffffff 27px, #ffffff 32px, transparent 32px), linear-gradient(to right, #ffffff, #ffffff), radial-gradient(circle at 0% 100%, transparent 27px, #ffffff 27px, #ffffff 32px, transparent 32px), linear-gradient(to bottom, #ffffff, #08060c), radial-gradient(circle at 0% 0%, transparent 27px, #080612 27px, #080612 32px, transparent 32px), linear-gradient(to left, #080612, #080612), radial-gradient(circle at 100% 0%, transparent 27px, #080612 27px, #080612 32px, transparent 32px), linear-gradient(to top, #080612, #ffffff);
        background-size: 32px 32px, calc(100% - 64px) 5px, 32px 32px, 5px calc(100% - 64px);
        background-position: top left,top center,top right,center right, bottom right,bottom center,bottom left,center left;
        background-repeat: no-repeat;
        margin-top: 15px;
    }
    .navigationMenu li:first-child{
        padding-top: 92px;
    }
    a{
        font-weight: 800;
        font-size: 55px;
        line-height: 66px;
    }
}
@media screen and (min-width: 1920px){
    .sheroLogo{
        width: 133px;
        height: 34px;
        margin-top: 34px;
        margin-left: 56px;
    }
    .burger{
        margin-top: 39px;
        margin-right: 63px;
    }
    .navigation{
        width: 1740px;
        margin: 0 auto;
        background-image: radial-gradient(circle at 100% 100%, transparent 27px, #ffffff 27px, #ffffff 32px, transparent 32px), linear-gradient(to right, #ffffff, #ffffff), radial-gradient(circle at 0% 100%, transparent 27px, #ffffff 27px, #ffffff 32px, transparent 32px), linear-gradient(to bottom, #ffffff, #08060c), radial-gradient(circle at 0% 0%, transparent 27px, #080612 27px, #080612 32px, transparent 32px), linear-gradient(to left, #080612, #080612), radial-gradient(circle at 100% 0%, transparent 27px, #080612 27px, #080612 32px, transparent 32px), linear-gradient(to top, #080612, #ffffff);
        background-size: 32px 32px, calc(100% - 64px) 5px, 32px 32px, 5px calc(100% - 64px);
        background-position: top left,top center,top right,center right, bottom right,bottom center,bottom left,center left;
        background-repeat: no-repeat;
        margin-top: 70px;
    }
    .navigationMenu li:first-child{
        padding-top: 150px;
    }
    a{
        font-size: 96px;
        line-height: 115px;
    }
}
