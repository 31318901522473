@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
#root{
    background-color: #080612;
    overflow-x: hidden;
}
.hidden_nav{
    opacity: 0;
    transition: opacity .5s ease-in-out;
    /*display: none !important;*/
}
a{
    cursor:pointer;
}
