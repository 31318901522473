.container{
    background-color: #080612;
    width: 300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.logo{
    margin: 95px auto 0;
    width: 168px;
    height: 44px;
}
.ul a{
    text-decoration: none;
    color: #FFFFFF;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
}
.ul{
    list-style: none;
    display: flex;
    justify-content: space-between;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
}
.ul:nth-child(2){
    margin-top: 50px;
    margin-bottom: 58px;
}
.ul:nth-child(3){
    justify-content: space-around;
    margin-bottom: 35px;
}
.copy{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 36px;
}
@media screen and (min-width: 1024px) {
    .container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .logo{
        width: 270px;
        height: 70px;
    }
    .ul{
        width: 480px;
    }
    .ul a{
        font-size: 24px;
    }
    .ul:nth-child(3) li{
        margin-right: 23px;
    }
    .ul:nth-child(3) li:last-child{
        margin-right: 0px;
    }

    .ul:nth-child(3){
        justify-content: center;
    }
}
