
.swiper-wrapper{
    overflow: unset;
}

.swiper-container {
    width: 100%;
    overflow: unset;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
}
.swiper-slide img{
    width: 365px;
}
.swiper-slide-active img{
    transition: 0.2s ease transform;
    transform: scale(1.2);
    position: relative;
    top: 10px;
    border-radius: 15px;
}
.swiperIMG {
    border-radius: 15px;
    display: block;
    width: 100%;
}


