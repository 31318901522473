.container{
    position: relative;
    background-color: #080612;
    display: flex;
    flex-direction: column;
    color: white;
    overflow: hidden;
    width: 480px;
    padding-bottom: 50px;
}
.header{
    font-family: "MonumentExtended-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
    color: #FFFFFF;
    text-align: center;
    padding-bottom: 31px;
}
.duplicate{
    width: 480px;
    transform: translate(19%, -15%);
    font-family: "MonumentExtended-Regular";
    position: absolute;
    top: 7px;
    left: -93px;
    font-style: normal;
    font-weight: normal;
    font-size: 41px;
    line-height: 53px;
    text-align: center;
    color: rgba(255, 255, 255, 0.05);
}
.contactStyleForm{
    padding: 34px 28px 0px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 440px;
    height: 642px;

    background-image: radial-gradient(circle at 100% 100%, transparent 27px, #ffffff 27px, #ffffff 32px, transparent 32px), linear-gradient(to right, #ffffff, #ffffff), radial-gradient(circle at 0% 100%, transparent 27px, #ffffff 27px, #ffffff 32px, transparent 32px), linear-gradient(to bottom, #ffffff, #08060c), radial-gradient(circle at 0% 0%, transparent 27px, #080612 27px, #080612 32px, transparent 32px), linear-gradient(to left, #080612, #080612), radial-gradient(circle at 100% 0%, transparent 27px, #080612 27px, #080612 32px, transparent 32px), linear-gradient(to top, #080612, #ffffff);
    background-size: 32px 32px, calc(100% - 64px) 5px, 32px 32px, 5px calc(100% - 64px);
    background-position: top left,top center,top right,center right, bottom right,bottom center,bottom left,center left;
    background-repeat: no-repeat

    /*background-image: radial-gradient(circle at 100% 100%, transparent 11px, #ffffff 11px, #ffffff 13px, transparent 13px), linear-gradient(to right, #ffffff, #ffffff), radial-gradient(circle at 0% 100%, transparent 11px, #ffffff 11px, #ffffff 13px, transparent 13px), linear-gradient(to bottom, #ffffff, #080612), radial-gradient(circle at 0% 0%, transparent 11px, #080612 11px, #080612 13px, transparent 13px), linear-gradient(to left, #080612, #080612), radial-gradient(circle at 100% 0%, transparent 11px, #080612 11px, #080612 13px, transparent 13px), linear-gradient(to top, #080612, #ffffff);*/
    /*background-size: 13px 13px, calc(100% - 26px) 2px, 13px 13px, 2px calc(100% - 26px);*/
    /*background-position: top left,top center,top right,center right, bottom right,bottom center,bottom left,center left;*/
    /*background-repeat: no-repeat*/
}
.label{
    font-family: "MonumentExtended-Regular";
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    margin-bottom: 5px;
}
.input{
    width: 384px;
    height: 52px;
    left: 48px;
    top: 2637px;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 15px;
    padding-left: 29px;
    background-color: transparent;
    margin-bottom: 25px;
    color: #ffffff;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: rgba(255, 255, 255, 1);
    margin-top: 6px;
}
.input::placeholder,.textarea::placeholder{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.6);
}
.textarea{
    margin-top: 6px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 18px;
    padding-bottom: 18px;
    width: 384px;
    height: 181px;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 15px;
    background-color: transparent;
    margin-bottom: 52px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: rgba(255, 255, 255, 1);
}
.input:focus, .textarea:focus{
    border-color: #A2F36F;
    outline: none;
}
.submitBtn{
    width: 286px;
    height: 61px;
    left: 97px;
    top: 3182px;
    border: 2px solid #A2F36F;
    box-sizing: border-box;
    border-radius: 15px;
    margin: 0 auto;
    background-color: transparent;
    cursor:pointer;
    color: #FFFFFF;
    font-family: "MonumentExtended-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 19px;
}
@media screen and (min-width: 1024px){
    .container{
        width: 100%;
    }
    .contactStyleForm{
        display: flex;
        flex-direction: column;
        width: 925px;
        height: 550px;
        margin: 0 auto;
    }
    .flexElement{
        display: flex;
        flex-direction: column;
    }
    .flexPosition{
        height: 324px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 100px;
    }
    .textarea{
        width: 406px;
        height: 295px;
        margin-bottom: 36px;
    }
    .input::placeholder,.textarea::placeholder{
        font-size: 14px;
    }
    .duplicate{
        width: 910px;
        top: 4px;
        left: -120px;
        font-size: 55px;
    }
    .textarea{
        font-size: 18px;
        color: rgba(255, 255, 255, 1);
    }
    .input{
        font-size: 18px;
        color: rgba(255, 255, 255, 1);
    }
}
@media screen and (min-width: 1920px){
    .header{
        position: relative;
        top: 8px;
        font-size: 48px;
        line-height: 58px;
    }
    .duplicate{
        width: 1565px;
        top: 0px;
        font-size: 96px;
        line-height: 115px;
    }
    .container{
        width: 100%;
    }
    .label{
        margin-bottom: 15px;
    }
    .contactStyleForm{
        margin-top: 100px;
        padding: 61px 93px 0px;
        display: flex;
        flex-direction: column;
        width: 1273px;
        height: 650px;
    }
    .flexElement{
        display: flex;
        flex-direction: column;
    }
    .flexPosition{
        height: 400px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 86px;
    }
    .textarea{
        width: 406px;
        height: 295px;
        margin-bottom: 36px;
    }
    .input::placeholder,.textarea::placeholder{
        font-size: 18px;
    }

    .textarea{
        width: 521px;
        height: 340px;
        margin-bottom: 0;
        font-size: 18px;
        color: rgba(255, 255, 255, 1);
    }
    .input{
        width: 521px;
        height: 81px;
        font-size: 18px;
        color: rgba(255, 255, 255, 1);
    }
    .submitBtn{
        width: 380px;
        height: 80px;
        font-size: 24px;
    }
}





