.header{
    margin-top: 138px;
    font-family: "MonumentExtended-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
    color: #FFFFFF;
    text-align: center;
    padding-bottom: 31px;
}
.duplicate{
    width: 480px;
    transform: translate(19%, -15%);
    font-family: "MonumentExtended-Regular";
    position: absolute;
    top: 138px;
    left: -93px;
    font-style: normal;
    font-weight: normal;
    font-size: 41px;
    line-height: 53px;
    text-align: center;
    color: rgba(255, 255, 255, 0.05);
}
.description{
    width: 440px;
    height: 44px;
    margin: 0 auto;
    font-family: "MonumentExtended-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 60px;
    color: #FFFFFF;
}
.contactInfo{
    display: flex;
    width: 401px;
    margin: 0 auto;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
}
.mailImg{
    width: 60px;
    padding-bottom: 50px;
}
.phoneImg{
    width: 52px;
    padding-bottom: 50px;
}
.mailInfo{
    margin-bottom: 50px;
}
.phoneInfo, .mailInfo{
    width: 401px;
}
.phone, .mail{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
}
.ul{
    width: 100%;
    margin-top: 60px;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
}
.socialImgLinks{
    width: 70px;
}
@media screen and (min-width: 1024px) {
    .header{
        font-weight: 800;
        font-size: 36px;
        line-height: 43px;
    }
    .duplicate{
        top: 130px;
        left: 170px;
        font-weight: 800;
        font-size: 72px;
        line-height: 86px;
    }
    .description{
        font-size: 24px;
        line-height: 29px;
        width: 902px;
    }
    .contactInfo{
        width: 875px;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }
    .contactInfo ul{
        width: 154px;
        margin-top: 0;
        flex-wrap: wrap;
    }
    .contactInfo ul li{
        width: 52px;
    }
    .socialImgLinks{
        width: 47px;
    }
    .phoneInfo img, .mailInfo img{
        width: 45px;
        padding-bottom: 0px;
    }
    .positions{
        width: 481px;
    }
    .positions a{
        display: flex;
        align-items: center;
    }
    .mail, .phone{
        font-size: 24px;
        line-height: 29px;
    }
    .positions a p{
        margin-left: 50px;
    }
}

@media screen and (min-width: 1920px) {
    .header{
        font-weight: 800;
        font-size: 64px;
        line-height: 77px;
    }
    .duplicate{
        top: 130px;
        left: 490px;
        font-weight: 800;
        font-size: 120px;
        line-height: 144px;
    }
    .description{
        font-size: 24px;
        line-height: 29px;
        width: 902px;
    }
    .contactInfo{
        width: 1162px;
        align-items: center;
    }
    .contactInfo ul{
        flex-wrap: wrap;
        width: 220px;
        margin-top: 0;
    }
    .contactInfo ul li{
        width: 75px;
    }
    .phoneInfo img, .mailInfo img{
        width: 58px;
    }
    .phoneInfo, .mailInfo{
        width: 590px;
    }
    .phoneInfo p, .mailInfo p{
        font-size: 29px;
        line-height: 37px;
    }
    .socialImgLinks{
        width: 64px;
    }
}
