.Container{
    padding-top: 130px;
    padding-bottom: 100px;
    overflow: hidden;
}
.header{
    font-family: "MonumentExtended-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
    color: #FFFFFF;
    text-align: center;
    padding-bottom: 31px;
}
.duplicate{
    width: 480px;
    transform: translate(19%, -15%);
    font-family: "MonumentExtended-Regular";
    position: absolute;
    top: 132px;
    left: -93px;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    color: rgba(255, 255, 255, 0.05);
}
.description{
    margin: 0 auto;
    width: 440px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
}
.images{
    padding-top: 70px;
    width: 440px;
    margin: 0 auto;
    margin-bottom: 100px;
}
.images img{
    margin-bottom: 30px;
}
.imagesSecondBlock{
    display: flex;
    justify-content: space-between;
}
.productionHeader{
    font-family: "MonumentExtended-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
    color: #FFFFFF;
    text-align: center;
    padding-bottom: 31px;
    text-transform: uppercase;
}
.productionDuplicate{
    width: 480px;
    transform: translate(19%, -15%);
    font-family: "MonumentExtended-Regular";
    position: absolute;
    top: 1150px;
    left: -93px;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    color: rgba(255, 255, 255, 0.05);
    text-transform: uppercase;
}
.prodProgress{
    margin-top: 70px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 100px;
    overflow-y: scroll;
}
.prodProgress::-webkit-scrollbar{
    width: 0px;
}
.sliderDesc{
    margin: 0 auto;
    margin-bottom: 40px;
    width: 440px;
    height: 86px;
    font-family: "MonumentExtended-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    text-align: center;

    color: #FFFFFF;
}
.teamHeader{
    padding-top: 108px;
    font-family: "MonumentExtended-Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
    color: #FFFFFF;
    text-align: center;
    padding-bottom: 31px;
}
.teamDuplicate{
    width: 480px;
    transform: translate(19%, -15%);
    font-family: "MonumentExtended-Regular";
    position: absolute;
    top: 2100px;
    left: -93px;
    font-style: normal;
    font-weight: normal;
    font-size: 42px;
    line-height: 58px;
    text-align: center;
    color: rgba(255, 255, 255, 0.05);
}
.teamName{
    margin-top: 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.teamPosition{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
}
@media screen and (min-width: 1024px) {
    .duplicate{
        font-weight: 800;
        font-size: 72px;
        line-height: 86px;
        left: 180px;
        top: 120px;
    }
    .header{
        font-weight: 800;
        font-size: 36px;
        line-height: 43px;
    }
    .description{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        width: 902px;
    }
    .images{
        display: flex;
        width: 902px;
        padding-top: 100px;
        justify-content: space-between;
    }
    .images img{
        width: 358px;
    }
    .imagesSecondBlock{
        flex-direction: column;
    }
    .imagesSecondBlock img{
        width: 513px;
    }
    .productionDuplicate{
        font-size: 67px;
        line-height: 86px;
        left: -199px;
        top: 1075px;
        width: unset;
    }
    .prodProgress{
        width: 902px;
        overflow-y: unset;
        padding: 0;
        margin: 80px auto;
    }
    .prodProgress img{
        width: 902px;
    }
    .sliderDesc{
        width: 902px;
        font-size: 24px;
        line-height: 29px;
    }
    .teamDuplicate{
        font-size: 72px;
        line-height: 86px;
        top: 2057px;
        left: -40px;
        width: unset;
    }
    .cards{
        width: 902px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 60px;
    }
    .cards img{
        width: 265px;
        margin-bottom: 75px;
        border-radius: 25px;
    }
    .cards img:nth-child(4){
        margin-bottom: 0;
    }
    .cards img:nth-child(5){
        margin-bottom: 0;
    }
    .cards img:nth-child(6){
        margin-bottom: 0;
    }
    .teamMemberCard img{
        margin: 0 !important;
    }
    .teamMemberCard .teamName {
        font-size: 24px;
        line-height: 29px;
    }
    .teamMemberCard .teamPosition{
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 32px;
    }
}

@media screen and (min-width: 1920px) {
    .header{
        font-size: 64px;
        line-height: 77px;
        padding-bottom: 50px;
    }
    .duplicate{
        left: 630px;
        top: 131px;
        font-weight: 800;
        font-size: 96px;
        line-height: 115px;
    }
    .description{
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        width: 1348px;
    }
    .images{
        width: 1360px;
    }
    .images img{
        width: 540px;
        margin: 0;
    }
    .imagesSecondBlock img{
        width: 774px;
    }
    .imagesSecondBlock img:last-child{
        margin-bottom: 0;
    }
    .productionHeader{
        font-weight: 800;
        font-size: 64px;
        line-height: 77px;
    }
    .productionDuplicate{
        left: -20px;
        top: 1295px;
        font-weight: 800;
        font-size: 92px;
        line-height: 110px;
    }
    .prodProgress{
        width: 1517px;
        margin-bottom: 150px;
    }
    .prodProgress img{
        width: 1517px;
    }
    .sliderDesc{
        width: 1357px;
        font-size: 35.4px;
        line-height: 42px;
    }
    .cards{
        width: 1357px;
    }
    .cards img{
        width: 400px;
        border-radius: 25px;
    }
    .teamHeader{
        font-weight: 800;
        font-size: 64px;
        line-height: 77px;
    }
    .teamDuplicate{
        font-weight: 800;
        font-size: 96px;
        line-height: 115px;
        top: 2615px;
        left: 221px;
    }
    .teamMemberCard{
        width: 423px;
    }
    .teamMemberCard img{
        width: 423px;
    }
    .teamName{
        font-size: 36px;
        line-height: 44px;
    }
    .teamPosition{
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
    }
}
